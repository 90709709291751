import React from "react"
import Layout from "../components/Layout/Layout"
import Achievment from "../components/Content/Achievment/Achievment"

export default function Home() {
  return (
    <Layout>
      <Achievment />
    </Layout>
  )
}
